/* Duplicated from restaurant-menus-showcase-ooi
Please note that any changes in this file should be also changed there */

import type { ComponentLayoutObject, PageDefinition } from '@wix/platform-editor-sdk';

export enum Preset {
  Columns = 0,
  Grid = 1,
  Center = 2,
  SideBySide = 3,
}

export const getPresetName = (preset: Preset) => {
  switch (preset) {
    case Preset.Grid:
      return 'grid';
    case Preset.Columns:
      return 'columns';
    case Preset.Center:
      return 'center';
    case Preset.SideBySide:
      return 'sideBySide';
    default:
      return '';
  }
};

export const WIDGET_IDS = {
  menus: '3f7c620e-526a-4dbb-afff-6fb77bfa8dcc',
};

export type PageData = {
  pageId: string;
  title: string;
  widgetId: string;
  pageUriSEO: string;
};

export const PAGE_DATA: PageData = {
  pageId: 'Menus',
  title: 'app.page.title',
  widgetId: WIDGET_IDS.menus,
  pageUriSEO: 'menu',
};

export const DEFAULT_APP_WIDTH = 980;
export type ComponentStructure = NonNullable<PageDefinition['components']>[number];

export const responsiveLayouts: Partial<ComponentLayoutObject> = {
  type: 'SingleLayoutData',
  componentLayout: {
    type: 'ComponentLayout',
    width: {
      type: 'auto',
    },
    height: {
      type: 'auto',
    },
  },
  itemLayout: {
    id: '',
    alignSelf: 'stretch',
    margins: {
      top: {
        type: 'px',
        value: 0,
      },
      right: {
        type: 'px',
        value: 0,
      },
      bottom: {
        type: 'px',
        value: 0,
      },
      left: {
        type: 'px',
        value: 0,
      },
    },
    gridArea: {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 1,
      columnEnd: 2,
    },
    justifySelf: 'stretch',
    type: 'GridItemLayout',
  },
};

export const widgetLayout = {
  height: 0,
  width: DEFAULT_APP_WIDTH,
  x: 0,
  y: 0,
  docked: {
    left: {
      vw: 0,
    },
    right: {
      vw: 0,
    },
  },
};

export const componentLayouts = [
  {
    type: 'ComponentLayout',
    height: {
      type: 'auto',
    },
    width: {
      type: 'auto',
    },
    minWidth: {
      type: 'px',
      value: 300,
    },
    minHeight: {
      type: 'px',
      value: 300,
    },
    hidden: false,
  },
];

export const containerLayouts = [
  {
    type: 'GridContainerLayout',
    rows: [
      {
        type: 'fr',
        value: 1,
      },
    ],
    columns: [
      {
        type: 'fr',
        value: 1,
      },
    ],
  },
];

export const itemLayouts = [
  {
    type: 'GridItemLayout',
    gridArea: {
      columnStart: 1,
      columnEnd: 2,
      rowStart: 1,
      rowEnd: 2,
    },
    alignSelf: 'stretch',
    justifySelf: 'stretch',
    margins: {
      left: {
        type: 'px',
        value: 0,
      },
      right: {
        type: 'px',
        value: 0,
      },
      top: {
        type: 'px',
        value: 0,
      },
      bottom: {
        type: 'px',
        value: 0,
      },
    },
  },
];
