import type { ComponentRef, EditorSDK, PresetValue, StyleParam } from '@wix/platform-editor-sdk';
import {
  LIST_1_COLUMN_PRESET_ID,
  LIST_2_COLUMN_PRESET_ID,
  LIST_3_COLUMN_PRESET_ID,
  LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID,
  SIDE_BY_SIDE_1_COLUMN_PRESET_ID,
  SIDE_BY_SIDE_2_COLUMN_PRESET_ID,
  GRID_PRESET_ID,
  CARD_MOBILE_PRESET_ID,
} from 'root/utils/consts';
import { getPresetName, Preset } from 'root/utils/OOI/consts';

export type Design = {
  preset: Preset;
  columns?: number;
};

const getPreset = (presetRes: PresetValue): Pick<Design, 'preset' | 'columns'> => {
  switch (presetRes.layout) {
    case LIST_1_COLUMN_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 1,
      };
    case LIST_2_COLUMN_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 2,
      };
    case LIST_3_COLUMN_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 3,
      };
    case LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID:
      return {
        preset: Preset.Columns,
      };
    case LIST_1_COLUMN_WITH_IMAGE_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 1,
      };
    case LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID:
      return {
        preset: Preset.Grid,
        columns: 3,
      };
    case SIDE_BY_SIDE_1_COLUMN_PRESET_ID:
      return {
        preset: Preset.SideBySide,
      };
    case SIDE_BY_SIDE_2_COLUMN_PRESET_ID:
      return {
        preset: Preset.SideBySide,
        columns: 2,
      };
    case CARD_MOBILE_PRESET_ID:
      return {
        preset: Preset.Center,
      };
    case GRID_PRESET_ID:
    default:
      return {
        preset: Preset.Grid,
        columns: 3,
      };
  }
};

export const getBlocksDesign = async ({
  editorSDK,
  ref,
}: {
  editorSDK: EditorSDK;
  ref: ComponentRef;
}): Promise<Design> => {
  const presetRes = await editorSDK.document.application.appStudioWidgets.getPreset('', {
    componentRef: ref,
  });

  const preset = getPreset(presetRes);

  return {
    ...preset,
  };
};

export const applyOoiDesign = async ({
  editorSDK,
  ref,
  design,
}: {
  editorSDK: EditorSDK;
  ref: ComponentRef;
  design: Design;
}) => {
  const styleParams: StyleParam[] = [
    {
      key: 'preset',
      param: {
        value: design.preset,
      },
      type: 'number',
    },
  ];

  if (design.columns) {
    styleParams.push({
      key: `${getPresetName(design.preset)}NumOfColumns`,
      param: {
        value: design.columns,
      },
      type: 'number',
    });
  }

  await editorSDK.document.tpa.setStyleParams('', {
    compRef: ref,
    styleParams,
  });
};
