import { openManageMenuDashboardPanel } from 'root/utils/OOI/panels';
import { BiReferral, MenuGFPPButtons, MenuPanelName, PAGE_NAME } from 'root/utils/OOI/bi';
import type { EditorScriptFlowAPI, FlowEditorSDK, TFunction, WidgetBuilder } from '@wix/yoshi-flow-editor';
import { restaurantsEditorPanelLoad } from '@wix/bi-logger-restaurants-data/v2';
import { MENU_WIDGET_NAME } from 'root/components/Menu/consts';
import { WIDGET_IDS } from 'root/utils/OOI/consts';

export const configureCommonGfpp = (
  widgetBuilder: WidgetBuilder,
  t: TFunction,
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('app.gfpp.manage-menus'),
      onClick: async () => {
        openManageMenuDashboardPanel(editorSDK, BiReferral.ManageMenusGfpp);
        const metaSiteId = await editorSDK.document.info.getMetaSiteId('');
        flowAPI.bi?.report(
          restaurantsEditorPanelLoad({
            origin: MenuGFPPButtons.Manage,
            panelName: MenuPanelName.Manage,
            product: PAGE_NAME,
            sitePageName: PAGE_NAME,
            widgetId: WIDGET_IDS.menus,
            widgetName: MENU_WIDGET_NAME,
            msid: metaSiteId,
            isOoi: true,
          })
        );
      },
    })
    .set('mainAction2', { behavior: 'DEFAULT1' });
};
